import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/gatsby-and-material-ui-update",
  "date": "2021-11-16T00:00:00.000Z",
  "title": "Gatsby and Material UI update"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Gatsby and Material UI update`}</h1>
    <p>{`Migrated `}<inlineCode parentName="p">{`Gatsby`}</inlineCode>{` to `}<inlineCode parentName="p">{`v4`}</inlineCode>{` and migrated `}<inlineCode parentName="p">{`Material UI`}</inlineCode>{` to `}<inlineCode parentName="p">{`v5`}</inlineCode>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      